@tailwind base;
@tailwind components;
@tailwind utilities;

/*TODO: headlessui fix for modal padding*/
html {
    padding: 0 !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-variation-settings: "dots" 9;
}

body {
    background-color: #ffffff;
    letter-spacing: -1px;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@-webkit-keyframes autofill {
    to {
        color: inherit;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

@-webkit-keyframes autofill {
    to {
        color: inherit;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

.product-card-content::before {
    content: '';
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
    width: 40px;
    border-bottom-left-radius: 6px;
    background: transparent;
    box-shadow: 0 20px 0 0 #ffffff;
}

.product-card-content::after {
    content: '';
    position: absolute;
    top: -40px;
    right: 0;
    height: 40px;
    width: 40px;
    border-bottom-right-radius: 6px;
    background: transparent;
    box-shadow: 0 20px 0 0 #ffffff;
}

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}




/*spinner*/
@keyframes spin-around {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animate-spin-around {
    animation: spin-around 2s linear infinite;
}
.bg-spinner:before{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/images/spinner/spinner-top.svg') bottom right no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
    content: '';
    right: 0;
    bottom: 0;
    -webkit-animation: spin 2s ease-in-out infinite;
    animation: spin 2s ease-in-out infinite;
}
.bg-spinner:after{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/images/spinner/spinner-bottom.svg') 0 0 no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
    content: '';
    top: 0;
    left: 0;
    -webkit-animation: spin 2s ease-in-out infinite;
    animation: spin 2s ease-in-out infinite;
}
/*spinner*/








.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #E5E8EB;
    border-radius: 10px;
    height: 10px;
}

.Toastify__toast-container--bottom-center {
    width: 450px !important;
    @media screen and (max-width: 430px) {
        width: 100% !important;
    }

}

@font-face {
    font-family: 'YekanBakh';
    src: url('/fonts/YekanBakhVariable/YekanBakh-VF.woff2') format('woff2'),
    url('/fonts/YekanBakhVariable/YekanBakh-VF.WOFF') format('woff');
    font-weight: normal;
    font-style: normal;
}

.archive-description ul{
    margin-top: 15px;
}
.archive-description h1,
.archive-description h2,
.archive-description h3,
.archive-description h4,
.archive-description h5,
.archive-description h6{
      margin-top: 10px;
}


/*single product page -> content tab -> details inner html styles*/

.archive-description li{
    font-weight: 500 !important;
    font-size: 14px !important;
}

.custom-inner-html {
    color: #424244;
}

.custom-inner-html p {
    color: #424244;
    line-height: 34px;
    font-weight:300
}

.custom-inner-html.archive-description p {
    color: #424244;
    line-height: 32px;
    font-size: 14px;
    font-weight: 300;
}

.custom-inner-html strong {
    font-size: 19px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -1px;
    text-align: right;
}

.custom-inner-html h1 {
    font-size: 19px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: right;
}

.custom-inner-html h2 {
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -1px;
    text-align: right;
    padding: 1rem 0;
}

.custom-inner-html h3 {
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -1px;
    text-align: right;
}

.custom-inner-html h4 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 26px;
    text-align: right;
}

.custom-inner-html h5 {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: right;
}

.custom-inner-html h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}

.custom-inner-html img {
    border-radius: 10px;
    margin: 14px auto;
}

.custom-inner-html ul {
    list-style: none; /* Remove default bullets */
    padding: 0;
    margin: 0;
}

.custom-inner-html li {
    font-size: 16px;
    font-weight:300;
    line-height: 32px;
    position: relative;
    padding-right: 16px;
    margin-right: 27px;
}

.custom-inner-html li:before {
    content: "◯"; /* Custom bullet symbol */
    position: absolute;
    right: 0;
    top: 0;
    color: #C3C3C3; /* Bullet color */
    font-size: 8px; /* Bullet size */
    font-weight: 900;
}

.custom-inner-html table {
    margin: 1em auto;
    border-collapse: collapse;
    text-indent: 0;
    border-color: inherit;
    width: 100%;
    border-spacing: 8px;
    box-shadow: 0px 10px 25px 0px #5B5E6812;
    background: #FFFFFF;
}
.custom-inner-html figure.table {
    @media only screen and (max-width: 768px){
        overflow-x: auto;
    }
}
.custom-inner-html table tr:not(:last-child) {
    border-bottom: 1px solid #eee;
}
.custom-inner-html table thead tr{
    border-bottom-width: 2px;
    box-shadow: 0 4px 25px 0px rgba(0, 0, 0, 0.06);
}
.custom-inner-html table th, td {
    padding: .75rem 0.5rem;
    text-align: center;
}
.custom-inner-html table td p{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: #4e4d4d;
}
.custom-inner-html table td p span {
    font-weight: 600 !important;
}

.custom-inner-html pre code {
    display: block;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: YekanBakh, monospace;
    background-color: #EFF5FF;
    border-radius: 8px;
    padding: 1rem;
}

.custom-inner-html video {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin: 14px auto;
}

.cart-addon-border::after {
    content: '';
    width: 5px;
    height: 150%;
    position: absolute;
    background-color: red;
    top: -100px;
    right: 90px;
    z-index: 1000;
}


.custom-input {
    @apply font-light block w-full rounded-lg border  border-gray-300 bg-white p-2.5 text-[15px] tracking-tighter text-[#76767C] outline-none transition duration-100 focus:border-[#FF9606] focus:shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)] focus:ring-[#FF9606];
}

.iframe-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .demo-iframe {
        transition: all .3s linear;
    }

    .demo-iframe.desktop {
        width: 100%;
        height: 100%;
    }

    .demo-iframe.tablet {
        width: 1040px;
        height: 600px;
        margin-top: 100px;
        border: 30px solid #eee;
        border-left: 20px solid #eee;
    }

    .demo-iframe.mobile {
        width: 455px;
        height: 680px;
        margin-top: 100px;
        border-radius: 5px;
        border: 30px solid #eee;
        border-left: 20px solid #eee;
    }
}

.container-transition {
    transition: max-height 0.5s ease-in-out;
}

.error-page-bg-mobile {
    @media only screen and (max-width: 768px){
        background-size: 100vh;
    }
    background-image: url("/images/errors/errorbg.svg");
    background-repeat: no-repeat;
}

/* Share Buttons Animation styles */
.network-list .network-item {
    position: absolute;
    display: block;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.43, 1.17, 0.69, 1.24);
}
.network-list.open .network-item {
    opacity: 1;
}
.network-list.open .network-item:nth-child(1) {
    transform: translateX(30px);
}
.network-list.open .network-item:nth-child(2) {
    transform: translateX(90px);
}
.network-list.open .network-item:nth-child(3) {
    transform: translateX(150px);
}
.network-list.open .network-item:nth-child(4) {
    transform: translateX(210px);
}

.share-button:before,
.share-button:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 1.5px;
    background-color: #5B5C60;
    top: 50%;
    left: 16px;
    transform-origin: center;
    transition: transform 0.3s ease, all 0.3s ease;
    opacity: 0;
}

.share-button:before {
    transform: rotate(0deg);
}

.share-button:after {
    transform: rotate(0deg);
}

.share-button.open:before {
    transform: rotate(45deg);
    animation: bar2 0.3s forwards;
    opacity: 1;
}

.share-button.open:after {
    transform: rotate(-45deg);
    animation: bar1 0.3s forwards;
    opacity: 1;
}

/* close button animation keyframes*/
@keyframes bar1 {
    0% {
        content: "\f1e0";
        width: 46px;
        height: 50px;
        background-color: transparent;
        transform: rotate(0deg) scale(1);
        top: 0;
        left: 0;
        opacity: 1;
    }

    50% {
        background-color: transparent;
        content: "\f1e0";
        width: 46px;
        height: 50px;
        top: 0;
        left: 0;
        transform: rotate(0deg) scale(0.2);
        opacity: 0;
    }
    50.001% {
        background-color: #fff;
        left: 10px;
        top: 22px;
        content: "";
        height: 3px;
        width: 30px;
    }

    60% {
        transform: rotate(0deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: rotate(45deg) scale(1);
    }
}


@keyframes bar2 {
    0% {
        background-color: transparent;
        transform: rotate(0deg) scale(0.2);
        opacity: 0;
    }

    50% {
        background-color: transparent;
        transform: rotate(0deg) scale(0.2);
        opacity: 0;
    }

    60% {
        transform: rotate(0deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: rotate(-45deg) scale(1);
    }
}

